import React, { useState } from 'react';
import './LoginForm.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
   const [username, setUsername] = useState('');//('admin');
   const [password, setPassword] = useState('');//('^:sEA,%FkQ_^i:a');

   const navigate = useNavigate();

   const handleLogin = async () => {
      console.log('Username:', username);
      console.log('Password:', password);


      try {
         const response = await fetch(global.API_URI+'/auth', {
           method: 'POST',
           headers: {
             'Content-Type': 'application/json'
           },
           body: JSON.stringify({
               login: username,
               password: password,
            })
         });
     
         if (response.ok) {
           const responseData = await response.json()
           global.TOKEN = responseData.Token
           global.ROLE = responseData.Role
           toast('ok')
           console.log('Данные успешно отправлены:', responseData);
           navigate('/cabinet');
         } else {
            toast('error')
            console.error('Ошибка при отправке данных:', response.statusText);
         }
       } catch (error) {
         toast(error)
         console.error('Ошибка:', error);
       }
    };


   return (
   <div className="login-form">
      <input
        type="text"
        placeholder="Логин"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Пароль"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>Войти</button>
      <ToastContainer />
    </div>
   )
};



export default LoginForm;
