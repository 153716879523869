import React, {useState, useEffect} from 'react'
import axios from 'axios';

const ImageViwer = (props) => {
  const [image, setImage] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => { 
      try {
          let config = {
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer '+global.TOKEN
              },
            }
          let body = JSON.stringify({
              Id: props.id,
            })
          const response = await axios.post(global.API_URI+'/images/id', body, config);
          setImage(response.data);
      } catch (error) {
          console.error('Ошибка при получении данных:', error);
      }
    };
    fetchData(); 
  }, [props.id]); 
  
  return (
    <div className='image'>
        {props.id===0?null:
        <img src={"https://images.cadetdiktant.ru/"+image.Hash+image.Extension} alt={image.Filename}/>
    }
    </div>
  )
}

export default ImageViwer