import React from 'react'
import AppRoutes from './AppRoutes'

import '../../styles/index.scss'

const App = () => {
    global.API_URI = 'https://api.cadetdiktant.ru'
    return (
    <div className='app'>
      <AppRoutes />
    </div>
    )
}

export default App